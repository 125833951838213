import React from 'react';
import './Competences.css';
import { useNavigate } from 'react-router-dom';

const Competences = () => {
  const competences = [
    { id: '1', title: "Propriété Intellectuelle", description: "Protégez vos idées et créations : j’assure la défense de vos droits d’auteur et de vos innovations." },
    { id: '2', title: "Droit informatique", description: "Naviguez sereinement dans le numérique : je sécurise vos contrats et vos données informatiques." },
    { id: '3', title: "Contentieux commercial", description: "Stratégie et protection de vos intérêts commerciaux : je vous accompagne de la négociation au contentieux devant les juridictions compétentes." },
    { id: '4', title: "Droit des Contrats", description: "Sécurisez vos engagements : rédaction et négociation de vos contrats commerciaux." },
    { id: '5', title: "Données personnelles", description: "Protégez la vie privée de vos utilisateurs : conformité RGPD et gestion des données personnelles." },
    { id: '6', title: "Droit rural", description: "Accompagnez votre exploitation avec sérénité : gestion des baux, litiges et réglementations agricole et rurale." },
  ];

  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate('/competences', { state: { selectedCompetence: id } });
  };
  const handleNavigationCompetencePage = () => {
    navigate("./competences");
  }
  return (
    <section className="competences-landing">
      <h2 className="competences-title-landing">Compétences</h2>
      <div className="competences-grid-landing">
        {competences.map((comp) => (
          <div
            key={comp.id}
            className="competence-card"
            onClick={() => handleCardClick(comp.id)}
            style={{ cursor: 'pointer' }}
          >
            <h3 className="competence-title-landing">{comp.title}</h3>
            <div className="underline"></div>
            <p className="competence-description-landing">{comp.description}</p>
          </div>
        ))}
      </div>
      <button onClick={handleNavigationCompetencePage} className="competences-button-landing">Toutes mes compétences</button>

    </section>
  );
};

export default Competences;
