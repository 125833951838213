import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import CompetenciesSection from './LP/competences';
import News from '../components/LP/news';
import RendezVous from '../components/LP/rendezvous';
import Contact from '../components/LP/contact';
import Header from './LP/header';
import GoogleReviewsSection from './LP/GoogleReviewsSection'; // Import the reviews component

function LandingPage() {
  const location = useLocation();
  const rendezVousRef = useRef(null);

  const scrollToContact = () => {
    if (rendezVousRef.current) {
      rendezVousRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.state?.scrollTo === 'rendez-vous') {
      document.getElementById('rendez-vous')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);



  return (
    <>
      <Header scrollToContact={scrollToContact} />
      <CompetenciesSection />
      <News />
      <div ref={rendezVousRef}>
        <RendezVous />
      </div>
      <GoogleReviewsSection/> {/* Pass reviews as prop */}
      <Contact />
    </>
  );
}

export default LandingPage;
