import React from 'react';
import './HonorairesSection.css';

const HonorairesSection = ({ scrollToContact }) => {
  const honoraires = [
    {
      title: "Temps passé",
      description: [
        "Pour les prestations qui nécessitent une analyse juridique approfondie ou une intervention continue, nos honoraires sont facturés au taux horaire. ",
        "En fonction de la complexité et du domaine d'expertise requis, le tarif horaire varie entre 220 € et 250 € HT.",
      ]
    },
    {
      title: "Forfait",
      description: [
        "Pour certaines missions ou dossiers qui peuvent être définis et encadrés à l’avance, il est également possible de mettre en place un honoraire forfaitaire. ",
        "Cette solution peut s’avérer plus avantageuse et permet de connaître à l’avance le coût total de l’intervention.",
        "Le montant du forfait dépendra de la nature et de la complexité du dossier. ",
      ]
    }
  ];

  const highlightEuroValues = (text) => {
    const regex = /(\d+ € +et|\d+ € +HT)/g;
    return text.split(regex).map((part, index) =>
      regex.test(part) ? <strong key={index}>{part}</strong> : part
    );
  };

  return (
    <section className="honoraires-section">
      <div className="honoraires-grid">
        {honoraires.map((item, index) => (
          <div key={index} className="honoraires-card">
            <div className="horaires-card-header-wrapper">
              <h3 className="honoraires-title">{item.title}</h3>
              <div className="honoraires-underline"></div>
            </div>
            <div className="honoraires-description">
              {item.description.map((line, idx) => (
                <p key={idx}>{highlightEuroValues(line)}</p>
              ))}
            </div>
            <button className="contact-button" onClick={scrollToContact}>
              Me contacter
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HonorairesSection;
