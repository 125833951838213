// components/competencespage.js
import React, {useState} from 'react';

import { Link,useLocation } from 'react-router-dom';
import Navbar from './LP/navbar';
import logoBlack from './LP/assets/logoblack.png';
import './Competences/CompetencesPage.css';

const CompetencesPage = () => {
  // Define the four competences with multi-line descriptions
  const location = useLocation();
  const [selectedCompetence, setSelectedCompetence] = useState(location.state?.selectedCompetence);
  const competences = [
    {
      id: '1',
      title: "Droit d'auteur",
      description: [`Protection et valorisation de vos créations. La propriété intellectuelle est essentielle pour sécuriser vos innovations et créations. Le cabinet vous accompagne dans la protection de vos droits d'auteur, brevets, marques, dessins et modèles. Défense de votre patrimoine immatériel face aux risques de contrefaçon et valorisation de vos actifs intellectuels grâce à des stratégies adaptées à votre secteur d’activité. De la rédaction de contrats de licence à l’action en contrefaçon, notre cabinet assure un accompagnement complet pour préserver et exploiter vos créations.`]
    },
    {
      id: '2',
      title: "Droit informatique",
      description: [
        "Sécurisez vos projets numériques. Le droit informatique est un domaine clé pour les entreprises évoluant dans le monde numérique. Le cabinet vous conseille sur la conformité de vos activités aux normes en vigueur, qu’il s’agisse de la rédaction de vos contrats informatiques et CGV, de la négociation de licences de logiciels ou encore de la mise en place de solutions pour protéger vos systèmes d’information. Le cabinet intervient également pour anticiper et résoudre les conflits liés aux technologies de l’information, tout en vous assurant une gestion optimale des risques juridiques associés."
      ]
    },
    {
      id: '3',
      title: "Contentieux Commercial",
      description: [
        "Défense de vos intérêts. Les différends commerciaux peuvent nuire à la stabilité et à la réputation de votre entreprise. Afin de limiter leur impact, je vous accompagne et vous représente devant les juridictions compétentes pour défendre vos intérêts et résoudre vos litiges. De la gestion des relations contractuelles à la protection contre les pratiques anticoncurrentielles, en passant par les impayés et les litiges clients et fournisseurs, le cabinet vous accompagne en combinant expertise juridique approfondie et stratégie procédurale. Notre  objectif : minimiser les risques juridiques et préserver la performance de votre entreprise."
      ]
    },
    {
      id: '4',
      title: "Droit des Contrats",
      description: [
        "Des contrats sécurisés pour des relations commerciales harmonieuses. Les contrats sont les fondements de vos relations commerciales. Qu'il s'agisse de contrats de vente, de partenariat, de distribution ou de prestations de services, le cabinet vous aide à rédiger, négocier et optimiser vos contrats pour éviter toute ambiguïté et prévenir les risques juridiques. Notre objectif est de garantir que vos engagements soient clairs, équilibrés et conformes à la législation en vigueur, tout en vous assurant une protection optimale en cas de litige."
      ]
    },
    {
      id: '5',
      title: "Données Personnelles",
      description: [
        "Conformité RGPD et protection de la vie privée. La gestion des données personnelles est au cœur des enjeux juridiques modernes. Le cabinet vous accompagne dans la mise en conformité avec le Règlement Général sur la Protection des Données (RGPD) et vous conseille sur les bonnes pratiques en matière de collecte, traitement et conservation des données personnelles. De l’audit de vos pratiques à la rédaction de politiques de confidentialité, le cabinet vous aide à protéger les informations sensibles tout en assurant votre conformité face aux exigences légales."
      ]
    },

    {
      id: '6',
      title: "Droit Rural",
      description: [
        "Conseil juridique pour les acteurs du monde équin et rural. Le droit rural couvre une vaste gamme de sujets liés à l’agriculture, l’environnement, l’exploitation des terres et les contrats agricoles. Disposant d’une connaissance particulière en droit équin, le cabinet vous accompagne dans toutes vos démarches juridiques, qu'il s’agisse de la gestion de vos exploitations équines et agricoles, des baux ruraux, de la réglementation environnementale ou de la défense de vos droits fonciers. Notre expertise permet de vous offrir des solutions juridiques sur mesure pour protéger vos intérêts dans un secteur en constante évolution."
      ]
    },

  ];
  const handleCardClick = (id) => {
    setSelectedCompetence(id);
  };
  return (
    <div>
      <div className="competences-header-wrapper">
        <Navbar textColor="var(--black)" logo={logoBlack} />
        <div className="competences-header">
          <h1>Compétences</h1>
        </div>
      </div>

      <section className="competences-page-content">
        <div className="competences-grid">
          {competences.map((comp) => (
            <div key={comp.id} className={`competence-card ${selectedCompetence === comp.id ? 'highlighted' : ''}`} onClick={() => handleCardClick(comp.id)}>
              <h3 className="competence-title">{comp.title}</h3>
              <div className="underline"></div>
              <div className="competence-description">
                {comp.description.map((section, index) => {
                  const sentences = section.split('. '); // Split the section into sentences
                  return (
                    <React.Fragment key={index}>
                      {sentences.map((sentence, idx) => (
                        <React.Fragment key={idx}>
                          {idx === 0 ? (
                            <strong>{sentence.trim()}.</strong> // Bold the first sentence
                          ) : (
                            <p><br />{sentence.trim()}.</p> // Each subsequent sentence in a new paragraph
                          )}
                        </React.Fragment>
                      ))}
                      {index < comp.description.length - 1 } {/* Add spacing between sections */}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CompetencesPage;

