import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom'; // Import useNavigate
import './Navbar.css';

const Navbar = ({ textColor, logo }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation(); // Get the current route
  const navigate = useNavigate(); // For navigation

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Scroll to the "Rendez-vous" section or redirect to home and scroll
  const handleContactClick = (e) => {
    e.preventDefault();

    if (location.pathname !== '/') {
      // Navigate to home and pass state to trigger scrolling
      navigate('/', { state: { scrollTo: 'rendez-vous' } });
    } else {
      // Scroll to the "Rendez-vous" section directly
      document.getElementById('rendez-vous')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Close the panel on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
        setIsMobileMenuOpen(false); // Close the mobile menu
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`header-top ${isScrolled ? 'scrolled' : ''}`} style={{ borderColor: textColor }}>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Camille Charles Logo" className="logo-image" />
        </Link>
      </div>
      <button className="hamburger" style={{ color: textColor }} onClick={toggleMobileMenu}>
        &#9776; {/* Simple hamburger icon */}
      </button>
      <nav className={`nav ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        {/* Highlight the active link */}
        <Link
          to="/cabinet"
          className={location.pathname === '/cabinet' ? 'active-link' : ''}
          style={{ color: textColor }}
        >
          Le Cabinet
        </Link>
        <Link
          to="/competences"
          className={location.pathname === '/competences' ? 'active-link' : ''}
          style={{ color: textColor }}
        >
          Compétences
        </Link>
        <Link
          to="/honoraires"
          className={location.pathname === '/honoraires' ? 'active-link' : ''}
          style={{ color: textColor }}
        >
          Honoraires
        </Link>
        <Link
          to="/actualites"
          className={location.pathname === '/actualites' ? 'active-link' : ''}
          style={{ color: textColor }}
        >
          Actualités
        </Link>
        <a
          href="#rendez-vous"
          onClick={handleContactClick} // Handle redirect and scroll
          className="navbar-contact-button"
          style={{ borderColor: textColor, color: textColor }}
        >
          Contact
        </a>
      </nav>
    </div>
  );
};

export default Navbar;
