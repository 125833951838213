import React, { useState, useEffect, useRef } from 'react';
import Navbar from './LP/navbar';
import logoBlack from './LP/assets/logoblack.png';
import RendezVous from './LP/rendezvous';
import HonorairesSection from './Honoraires/honorairesSection';
import GooglePlaceDetails from './LP/GoogleReviewsSection'; // Import the reviews component

const HonorairePage = () => {
  const rendezVousRef = useRef(null);


  const scrollToContact = () => {
    if (rendezVousRef.current) {
      rendezVousRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="competences-header-wrapper">
        <Navbar textColor="var(--black)" logo={logoBlack} />
        <div className="competences-header">
          <h1>Mes Honoraires</h1>
        </div>
      </div>
      <HonorairesSection scrollToContact={scrollToContact} />
      <GooglePlaceDetails /> {/* Pass reviews as prop */}
      <div ref={rendezVousRef}>
        <RendezVous />
      </div>
    </>
  );
};

export default HonorairePage;
