import React from 'react';
import profileImage from '../LP/assets/cabinetpage.jpg'; // Replace with the actual path of your image
import './CabinetSection.css';

const CabinetSection = ({ scrollToContact }) => {
  return (
    <div className="cabinet-section">
      <div className="cabinet-section-content">
        <div className="cabinet-content">
          <h2>Maître Camille CHARLES</h2>
          <p>
            Maître CHARLES est titulaire d’un Master 2 en Droit de la Propriété
            Intellectuelle et des Nouvelles Technologies obtenu à l’Université
            de NICE COTE D’AZUR.
          </p>
          <p>
            Bilingue, elle a débuté son parcours en Angleterre, en intégrant un
            double cursus de droit français et anglais au sein de l’Université
            d’Essex, avant de revenir en France pour continuer son cursus en
            Droit des Affaires à la Faculté de Lyon 3 puis de Toulouse Capitole.
          </p>
          <p>
            Maître CHARLES a débuté son parcours professionnel au sein d’un
            cabinet spécialisé en propriété intellectuelle et droit informatique
            avant de rejoindre un cabinet pluridisciplinaire et à rayonnement
            international
          </p>
          <p>
            Inscrite au Barreau de GRASSE, son cabinet est situé à CANNES et
            intervient dans tout le département des Alpes Maritimes 06 (Nice,
            Cannes, Le Cannet, Antibes, Mandelieu, Valbonne, Vallauris,
            Golfe-Juan, Saint Laurent du Var, Roquefort les Pins, Le Rouret,
            Chateauneuf).
          </p>
          <p>
            Ses engagements : confiance, transparence, expertise, écoute et
            réactivité.
          </p>
          <p>
            Pour toute question ou besoin d'assistance, n’hésitez pas à
            contacter le cabinet de Maître Camille CHARLES.
          </p>
          <button onClick={scrollToContact} className="rendez-vous-button">
            Prendre Rendez-vous
          </button>
        </div>

        <div className="profile-image-container">
          <img
            src={profileImage}
            alt="Camille Charles"
            className="profile-image"
          />
          <div className="social-links">
            <a
              href="https://www.linkedin.com/in/camille-charles-2a011b86/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              in
            </a>
            <a
              href="https://maps.app.goo.gl/sAQRHM48oyZMNoYv6"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              G
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabinetSection;
