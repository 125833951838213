import React from 'react';
import Navbar from './LP/navbar';
import logoBlack from './LP/assets/logoblack.png';
import './Actualitées/ActualitePage.css';
import newsImage1 from './LP/assets/placeholderArticles1.png';
import newsImage2 from './LP/assets/placeholderArticles2.jpg';

const initialNewsItems = [
  {
    id: "1",
    image: newsImage1,
    title: "A venir...",
    description: "",
    date: "",
    category: "",
  },
  {
    id: "2",
    image: newsImage2,
    title: "A venir...",
    description: "",
    date: "",
    category: "",
  },
  // Add more news items as needed
];

const ActualitePage = () => {
  return (
    <>
      <div className="actualite-header-wrapper">
        <Navbar textColor="var(--black)" logo={logoBlack} />
        <div className="actualite-header">
          <h1>Actualités</h1>
        </div>
      </div>

      <section className="news-page">
        <div className="news-grid">
          {initialNewsItems.map((item) => (
            <div key={item.id} className="news-card">
              <img src={item.image} alt={item.title} className="news-image" />
              <div className="news-content">
                <h3 className="news-card-title">{item.title}</h3>
                <p className="news-description">{item.description || ""}</p>
                <div className="news-footer">
                  <span className="news-date">
                    📅 {item.date ? new Date(item.date).toLocaleDateString() : ""}
                  </span>
                  <span className="news-category-tag">{item.category }</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ActualitePage;
