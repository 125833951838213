import React, { useRef } from 'react';
import Navbar from './LP/navbar';
import logoBlack from './LP/assets/logoblack.png';
import CabinetSection from './Cabinet/CabinetSection';
import Contact from './LP/contact';

const CabinetPage = () => {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="competences-header-wrapper">
        <Navbar textColor="var(--black)" logo={logoBlack} />
        <div className="competences-header">
          <h1>Le Cabinet</h1>
        </div>
      </div>
      <CabinetSection scrollToContact={scrollToContact} />
      <div ref={contactRef}>
        <Contact className="cabinet-section-contact" />
      </div>
    </>
  );
};

export default CabinetPage;
