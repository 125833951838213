// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/LP/navbar';
import Footer from './components/LP/footer';
import LandingPage from './components/landingpage';
import CompetencesPage from './components/competencespage';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HonorairePage from './components/honorairepage';
import ActualitePage from './components/actualitespage';
import logoWhite from './components/LP/assets/logo.png'
import CabinetPage from './components/cabinetpage';

function App() {
  return (
    <Router>
      <main>
      <Navbar textColor="var(--white)" logo={logoWhite} />

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/competences" element={<CompetencesPage />} />
          <Route path="/cabinet" element={<CabinetPage />} />
          <Route path="/honoraires" element={<HonorairePage />} />
          <Route path='/actualites' element={<ActualitePage />} />
        </Routes>
        <Footer />

      </main>
    </Router>
  );
}

export default App;
